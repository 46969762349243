.miniChat .miniChatWindow {
    background-color: #fffb7d;
    padding: 5px;
    box-shadow: 0px -1px 5px gray inset;
    border-radius: 3px;
    transform: rotateX(180deg);
    overflow-y: scroll;
    max-height: 5em;
    min-height: 1em;
}

.miniChat .miniChatWindow .entry {
    padding-top: 5px;
    padding-bottom: 5px;
    transform: rotateX(180deg);
}

.miniChat .miniChatWindow .self {
    text-align: right;
}

.miniChat .miniChatWindow .entry .player {
    color: gray;
    font-size: 0.7em;
}

.miniChat .miniChatWindow .entry .message {
    border-radius: 0px 10px 10px 10px;
    background-color: whitesmoke;
    padding: 2px;
    padding-left: 6px;
    box-shadow: 1px 1px 3px rgb(173, 150, 129);
    display: inline-block;
    min-width: 30%;
    text-align: left;
}

.miniChat .miniChatWindow .self .message {
    border-radius: 10px 0px 10px 10px;
}

.miniChat .inputArea {
    background-color: whitesmoke;
    width: 100%;
}

.miniChat .inputArea textarea {
    width: 80%;
    border: none;
    box-shadow: 1px 1px 3px gray inset;
    background-color: rgb(223, 223, 223);
}

.miniChat .inputArea button {
    width: 17%;
    vertical-align: top;
}

.miniChat-collapsed {
    text-align: left;
    display: block;
    z-index: 10;
    padding-right: 5px;
    box-shadow: 0 0 3px grey;
}

.miniChat-collapsed .indicator {
    color: white;
    background-color: red;
    border-radius: 50px;
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    text-align: center;
    font-size: 0.5em;
    vertical-align: top;
}

.miniChat-collapsed .expand {
    float: right;
    text-align: center;
    padding-right: 5px;
}
