.players {
    padding: 10px;
}

.players .bold {
    font-weight: bold;
}

.borad-wrapper {
    overflow-y: scroll;
    padding: 10px;
}
.board {
    background-color: rgb(189, 130, 54);
    border-bottom: 15px solid black;
    border-right: 4px solid black;
    border-left: 4px solid rgb(112, 77, 32);
    padding: 3px;
    font-size: 2em;
    max-width: 15em;
    min-width: 12em;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0px 2px 4px 2px grey;
}

.row.solution {
    background-color: #a06f2e;
}

.row {
    margin-bottom: 2px;
    border: 1px solid rgb(71, 53, 28);
}

.row.active {
    margin-bottom: 2px;
    background-color: rgb(204, 154, 89);
    border: 3px solid rgb(255, 215, 160);
}

.row .number {
    display: inline-block;
    width: 5%;
    font-size: 1rem !important;
    vertical-align: top;
}

.row .hintPins {
    display: inline-block;
    width: 30%;
}
.row .solutionPins {
    display: inline-block;
    width: 50%;
}
.row .guessPins {
    display: inline-block;
    width: 50%;
    transform: translateY(-0.5em);
}
.row .submit-solution {
    display: inline-block;
    width: 10%;
}
.row .submit {
    display: inline-block;
    width: 10%;
    transform: translateY(-0.5em);
}

.pin {
    text-shadow: 3px 4px 3px black;
}

.MuiTypography-body1 {
    font-size: 2em !important;
}
