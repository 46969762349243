.mainChat .chatWindow {
    height: 70vh;
    background-color: #fffb7d;
    padding: 5px;
    box-shadow: 1px 1px 3px gray inset;
    border-radius: 3px;
    overflow-y: scroll;
    transform: rotateX(180deg);
}

.mainChat .chatWindow .entry {
    padding-top: 5px;
    padding-bottom: 5px;
    transform: rotateX(180deg);
}

.mainChat .chatWindow .self {
    text-align: right;
}

.mainChat .chatWindow .entry .player {
    color: gray;
    font-size: 0.7em;
}

.mainChat .chatWindow .entry .message {
    border-radius: 0px 10px 10px 10px;
    background-color: whitesmoke;
    padding: 4px;
    box-shadow: 1px 1px 3px rgb(173, 150, 129);
    display: inline-block;
    min-width: 30%;
    text-align: left;
}

.mainChat .chatWindow .self .message {
    border-radius: 10px 0px 10px 10px;
}

.mainChat .inputArea {
    background-color: whitesmoke;
    margin-top: 10px;
    padding: 4px;
}

.mainChat .inputArea textarea {
    width: 78%;
    border-radius: 10px 0 0 10px;
    border: none;
    box-shadow: 1px 1px 3px gray inset;
    background-color: rgb(223, 223, 223);
    min-height: 2em;
    padding: 10px 0 10px 10px;
}

.mainChat .inputArea button {
    width: 18%;
    height: 4em;
    vertical-align: top;
}
