html,
body {
    margin: 0;
    height: 100%;
    overflow-y: scroll;
}
.header {
    background-color: #dcf5f4;
    box-shadow: 0 0 5px gray;
    position: absolute;
    display: block;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 4em;
}
.header-content {
    padding-left: 10px;
}
.content {
    padding-top: 4em;
    height: 100vh -4em;
    overflow: scroll;
}
