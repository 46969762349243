.game-toolbar {
    padding: 10px;
}
.game-toolbar .bold {
    font-weight: bold;
}

.game-toolbar .figure {
    box-shadow: none !important;
    display: inline-block;
    padding: 0;
    vertical-align: bottom;
}

.game-toolbar .bold .figure {
    font-weight: bold;
    padding: 3px;
}

.game-toolbar .tool {
    text-align: center;
    width: 1.5em;
    height: 1.5em;
    border-radius: 3px;
    display: inline-block;
    margin-right: 5px;
    box-shadow: 0 0 3px black;
    cursor: pointer;
}

.game-toolbar .tool.pressed {
    background-color: rgb(206, 247, 248);
    box-shadow: 0 0 3px black inset;
}

.board-wrapper {
    overflow: scroll;
}
.malefiz-board {
    width: 680px;
    background-color: rgb(240, 203, 135);
    border: 20px solid rgb(43, 128, 255);
    box-shadow: 0 0 5px gray;
    border-radius: 5px;
    margin: 10px;
    padding: 20px;
}
.malefiz-board.zoom100 {
    width: 680px;
}
.malefiz-board.zoom50 {
    width: 340px;
}

.board-row {
    width: 100%;
}

.board-row .board-col {
    display: inline-block;
    width: 40px;
    height: 40px;
}
.malefiz-board.zoom50 .board-row .board-col {
    width: 20px;
    height: 20px;
}
.field {
    background-color: black;
    width: 30px;
    height: 30px;
    margin: 5px;
    border-radius: 50px;
}
.field:hover {
    cursor: pointer;
}
.field.selected {
    box-shadow: 0 0 5px 5px rgb(35, 255, 35);
}
.malefiz-board.zoom50 .field {
    width: 15px;
    height: 15px;
    margin: 1.5px;
}

.border-field {
    margin: 0;
    border: 5px solid rgb(255, 255, 255);
}

.malefiz-board.zoom50 .border-field {
    margin: 0;
    border: 2.5px solid rgb(255, 255, 255);
}

.barrier-field {
    background-color: rgb(168, 37, 59);
}

.player1-field {
    background-color: rgb(190, 39, 64);
}
.player2-field {
    background-color: rgb(0, 138, 73);
    border-color: rgb(107, 93, 15);
}
.player3-field {
    background-color: rgb(247, 243, 56);
    border-color: rgb(107, 93, 15);
}
.player4-field {
    background-color: rgb(0, 121, 219);
    border-color: rgb(255, 255, 255);
}
.winner-field {
    background-color: rgb(226, 226, 226);
    border-color: rgb(14, 14, 41);
}

.figure {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    border: 5px solid white;
    padding: 5px;
    box-shadow: -7px 5px 3px -1px rgb(48, 48, 48);
}
.zoom50 .figure {
    height: 5px;
    width: 5px;
    border-radius: 50px;
    border: 3px solid white;
    padding: 3px;
    box-shadow: -5px 3px 3px -1px rgb(48, 48, 48);
}

.figure.red {
    background: rgb(190, 39, 64);
    border-color: rgb(139, 26, 45);
}

.figure.green {
    background: rgb(0, 138, 73);
    border-color: rgb(0, 110, 59);
}

.figure.yellow {
    background: rgb(247, 243, 56);
    border-color: rgb(231, 213, 51);
}

.figure.blue {
    background: rgb(0, 121, 219);
    border-color: rgb(8, 94, 163);
}

.barrier {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    padding: 10px;
    box-shadow: -4px 4px 6px 3px rgb(48, 48, 48);
    background-color: beige;
}

.zoom50 .barrier {
    height: 5px;
    width: 5px;
    border-radius: 50px;
    padding: 6px;
    box-shadow: -5px 3px 3px -1px rgb(48, 48, 48);
}
